
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import {Getter} from "vuex-class";
    import {
        ArrayConditionOption, Condition,
        FactOption,
        FactOptionGroup, FactType,
        OperatorOption,
        ValueCondition as ValueConditionModel
    } from "@/models/condition";
    import SelectBox from '@/components/Input/SelectBox.vue';
    import DatePicker from '@/components/Input/DatePicker.vue';
    import CurrencyAmountInput from '@/components/Input/CurrencyAmountInput.vue';
    import Icon from '@/components/Icon.vue';
    import conditionValidator from '@/util/ConditionValidator';
    // const namespace: string = 'core';


    @Component({
        components: {
            Icon,
            SelectBox,
            DatePicker,
            CurrencyAmountInput,
        }
    })
    export default class ValueCondition extends Vue {
        private isNew: boolean = false;
        private valueOptions: ArrayConditionOption[] = [];
        @Prop({required: true}) private condition!: ValueConditionModel;
        @Getter('factOptions') factOptions!: FactOption[];

        get fact(): FactOption | undefined {
            for (let i = 0; i < this.factOptions.length; i++) {
                if(this.factOptions[i].id === this.condition.fact) {
                    return this.factOptions[i];
                }
            }
        }

        get operator(): OperatorOption | undefined {
            if(this.fact) {
                for (let i = 0; i < this.fact.operators.length; i++) {
                    if(this.fact.operators[i].id === this.condition.operator) {
                        return this.fact.operators[i];
                    }
                }
            }
        }

        get operatorOptions(): OperatorOption[] {
            if(this.fact && this.fact.operators) {
                return this.fact.operators;
            }
            return [];
        }

        // get valueOptions(): ArrayConditionOption[] {
        //     if(this.fact && this.fact.options) {
        //         return this.fact.options();
        //     }
        //     return [];
        // }

        get arrayValue(): OperatorOption[] | undefined {
            if(this.valueOptions && Array.isArray(this.condition.value)) {
                return this.valueOptions.filter((option) => this.condition.value.includes(option.id));
            }
        }

        get isStringFact(): boolean {
            return this.fact !== undefined && this.fact.type === FactType.string;
        }

        get isNumberFact(): boolean {
            return this.fact !== undefined && this.fact.type === FactType.number;
        }

        get isDateFact(): boolean {
            return this.fact !== undefined && this.fact.type === FactType.date;
        }

        get isArrayFact(): boolean {
            return this.fact !== undefined && this.fact.type === FactType.array;
        }

        get isCurrencyAmountFact(): boolean {
            return this.fact !== undefined && this.fact.type === FactType.currencyAmount;
        }

        get showOperatorInput(): boolean {
            return this.condition.fact !== null;
        }

        get showValueInput(): boolean {
            return this.condition.fact !== null && this.condition.operator !== null;
        }

        public isValid(condition: Condition): boolean {
            if(this.isNew) {
                return true;
            }
            return conditionValidator.validate(condition);
        }

        public async setValueOptions(): Promise<void> {
            if(this.fact && this.fact.options) {
                if(typeof this.fact.options === 'function') {
                    this.valueOptions = await this.fact.options();
                } else {
                    this.valueOptions = this.fact.options;
                }
            }
        }

        public onRemove(): void {
            this.emitRemoveGroup();
        }

        public onFactChange(fact: FactOption): void {
            this.condition.fact = fact.id;
            this.condition.operator = null;
            this.condition.value = null;
            this.emitInput();
        }

        public onOperatorChange(operator: OperatorOption): void {
            this.condition.operator = operator.id;
            this.condition.value = null;
            this.emitInput();
        }

        public onValueChange(value: any): void {
            if(Array.isArray(value)) {
                this.condition.value = value.map(option => option.id);
            } else {
                this.condition.value = value;
            }
            this.isNew = false;
            this.emitInput();
        }

        @Watch('fact')
        valueOptionsChanged(options: ArrayConditionOption[] | Function) {
            this.setValueOptions()
        }

        @Emit("input")
        public emitInput(): ValueConditionModel {
            return this.condition;
        }

        @Emit("remove")
        public emitRemoveGroup(): ValueConditionModel {
            return this.condition;
        }

        mounted() {
            if(this.condition.fact === null && this.condition.operator === null && this.condition.value === null) {
                this.isNew = true;
            }
            this.setValueOptions();
        }

    }
