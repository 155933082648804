
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

    @Component
    export default class TitleInput extends Vue {
        private v: string|null = null;
        @Prop() private value!: string;

        @Watch('value')
        valueChanged(newVal: string) {
            this.setValue(newVal);
        }

        @Emit("input")
        public onChange(): string|null {
            return this.v;
        }

        public setValue(value:string): void {
            this.v = value;
        }

        mounted() {
            this.setValue(this.value);
        }

    }
