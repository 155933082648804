
    import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
    import {Getter} from "vuex-class";
    import { v4 as uuidv4 } from 'uuid';
    import ValueCondition from '@/components/ConditionEditor/ValueCondition.vue';
    import SelectBox from '@/components/Input/SelectBox.vue';
    import Icon from '@/components/Icon.vue';
    import {GroupCondition as GroupConditionModel, Condition, instanceOfGroupCondition, OperatorOption} from "@/models/condition";
    // const namespace: string = 'core';

    @Component({
        name: "GroupCondition",
        components: {
            GroupCondition,
            ValueCondition,
            SelectBox,
            Icon,
        }
    })
    export default class GroupCondition extends Vue {
        @Prop({required: true}) private condition!: GroupConditionModel;
        @Prop({default: 0}) private level?: null;
        @Getter('groupOperators') groupOperators!: OperatorOption[];


        get isRoot(): boolean {
            return this.level !== null && this.level !== undefined && this.level === 0;
        }

        get operator(): OperatorOption | undefined {
            for (let i = 0; i < this.groupOperators.length; i++) {
                if(this.groupOperators[i].id === this.condition.operator) {
                    return this.groupOperators[i];
                }
            }
        }

        public isGroup(condition: Condition): boolean {
            return instanceOfGroupCondition(condition);
        }

        public onOperatorChange(operator: OperatorOption): void {
            this.condition.operator = operator.id;
            this.emitInput();
        }

        public onConditionChange(): void {
            this.emitInput();
        }

        public onAddGroup(): void {
            this.condition.conditions.push({
                id: uuidv4(),
                operator: 'or',
                conditions: [{
                    id: uuidv4(),
                    fact: null,
                    operator: null,
                    value: null
                }]
            });
            this.emitInput();
        }

        public onRemoveGroup(): void {
            this.emitRemoveGroup();
            this.emitInput();
        }

        public onAddCondition(): void {
            this.condition.conditions.push({
                id: uuidv4(),
                fact: null,
                operator: null,
                value: null
            });
            this.emitInput();
        }

        public onRemoveCondition(condition: Condition): void {
            this.condition.conditions = this.condition.conditions.filter(c => c.id !== condition.id);
            this.emitInput();

            // remove group if all child conditions are removed
            if(this.condition.conditions.length === 0) {
                this.emitRemoveGroup();
            }
        }

        @Emit("input")
        public emitInput(): GroupConditionModel {
            return this.condition;
        }

        @Emit("remove")
        public emitRemoveGroup(): GroupConditionModel {
            return this.condition;
        }

    }
