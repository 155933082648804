
    import {Component, Vue, Watch} from "vue-property-decorator";
    import {Action, Getter} from "vuex-class";
    import BaseLayout from "@/views/BaseLayout.vue";
    import SegmentEditor from "@/modules/segment/components/SegmentEditor.vue";
    import {Segment, SegmentType} from "@/models/segment";
    const namespace: string = 'segment';

    @Component({
        components: {
            BaseLayout,
            SegmentEditor,
        }
    })
    export default class SegmentDetailEdit extends Vue {
        @Action('fetchSegment', { namespace }) fetchSegment: any;
        @Action('createSegment', { namespace }) createSegment: any;
        @Action('editSegment', { namespace }) editSegment: any;
        @Action('removeSegment', { namespace }) removeSegment: any;
        @Getter('segment', { namespace }) segment?: Segment;

        get isLoading(): boolean {
            return false;
        }

        get isNew(): boolean {
            return this.$route.params.id === 'new';
        }

        get canEdit(): boolean {
            if((this.segment && this.segment.type === SegmentType.dynamic) || this.isNew) {
                return true;
            }
            return false
        }

        get cannotEditMessage(): string|null {
            if(this.segment && this.segment.type === SegmentType.prebuild) {
                return String(this.$t('condition.editor.cannotEditPrebuildSegments'));
            }
            if(this.segment && this.segment.type === SegmentType.static) {
                return String(this.$t('condition.editor.cannotEditStaticSegments'));
            }
            return null;
        }

        public onSegmentSave(segment: Segment): void {
            if(this.isNew) {
                this.createSegment({segment});
            } else {
                this.editSegment({segment});
            }
        }

        public onSegmentDelete(segment: Segment): void {
            this.removeSegment({id: segment.id});
        }

        // mounted() {
        //     this.fetchSegment({id: this.$route.params.id});
        // }

    }
