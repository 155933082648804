import { render, staticRenderFns } from "./SegmentOverview.vue?vue&type=template&id=d71a4950&scoped=true&"
import script from "./SegmentOverview.vue?vue&type=script&lang=ts&"
export * from "./SegmentOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d71a4950",
  null
  
)

export default component.exports