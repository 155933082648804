
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { State, Action, Getter } from 'vuex-class';
    import BaseLayout from '@/views/BaseLayout.vue';
    import Panel from '@/components/Panel.vue';
    import BasicTable from '@/components/BasicTable.vue';
    import Button from "@/components/Button.vue";
    import {SegmentSummery} from '@/models/segment';
    import {TableColumn} from '@/models/table';
    import loading from '@/util/Loading';
    const namespace: string = 'segment';

    @Component({
        components: {
            BaseLayout,
            Panel,
            BasicTable,
            'te-button': Button,
        }
    })
    export default class SegmentOverview extends Vue {
        @Action('fetchSegments', { namespace }) fetchSegments: any;
        @Getter('segments', { namespace }) segments?: SegmentSummery[];

        get isLoading(): boolean {
            // return false;
            return loading.isLoading(['get-segments']);
        }

        get columns(): TableColumn[] {
            return [
                {key: 'name', label: this.$t('common.name'), link: {routeName: 'segment-detail-edit', params: [{paramKey: 'id', valueKey: 'id'}]}},
                {key: 'type', label: this.$t('common.type')},
                // {key: 'count', label: this.$t('segment.members')},
            ];
        }

        public onCreateSegment(): void {
            this.$router.push({name:'segment-detail-edit', params: {id: 'new'}});
        }

        mounted() {
            this.fetchSegments({filters: {}});
        }
    }
