
    import {Component, Vue, Watch} from "vue-property-decorator";
    import {Action, Getter} from "vuex-class";
    import moment from "moment";
    import BaseLayout from "@/views/BaseLayout.vue";
    import BasicTable from '@/components/BasicTable.vue';
    import Icon from '@/components/Icon.vue';
    import Button from "@/components/Button.vue";
    import DefaultModal from '@/components/DefaultModal.vue';
    import SegmentIntegrationEditor from '@/modules/segment/components/SegmentIntegrationEditor.vue';
    import {IntegrationConfig, Segment, SegmentIntegration} from "@/models/segment";
    import {TableColumn} from '@/models/table';
    import {TranslateResult} from 'vue-i18n';
    import {ModalInterface} from '@/models/modal';
    const namespace: string = 'segment';

    @Component({
        components: {
            BaseLayout,
            BasicTable,
            Icon,
            DefaultModal,
            SegmentIntegrationEditor,
            'te-button': Button,
        }
    })
    export default class SegmentDetailEdit extends Vue {
        @Action('fetchSegment', { namespace }) fetchSegment: any;
        @Action('fetchIntegrationConfigs', { namespace }) fetchIntegrationConfigs: any;
        @Action('fetchSegmentIntegrations', { namespace }) fetchSegmentIntegrations: any;
        @Action('createSegmentIntegration', { namespace }) createSegmentIntegration: any;
        @Action('removeSegmentIntegration', { namespace }) removeSegmentIntegration: any;
        // @Getter('integrationConfigs', { namespace }) integrationConfigs?: IntegrationConfig[];
        @Getter('segment', { namespace }) segment?: Segment;
        @Getter('segmentIntegrations', { namespace }) segmentIntegrations?: SegmentIntegration[];

        get isLoading(): boolean {
            return false;
        }

        get integrations(): Array<{id: string; network: string | TranslateResult; label: string;}> {
        // get integrations(): Array<{id: string; label: string | TranslateResult; syncStatus: string; lastSyncOn: string;}> {
            // return this.segment && this.segment.integrations ? this.segment.integrations.map((s) => {
            //     return {
            //         id: s.id,
            //         label: this.$t('integration.' + s.network + '.label'),
            //         syncStatus: s.syncStatus.charAt(0).toUpperCase() + s.syncStatus.slice(1),
            //         lastSyncOn: moment(s.lastSyncOn).format('D MMM YYYY @ HH:mm'),
            //     }
            // }) : [];
            return this.segmentIntegrations ? this.segmentIntegrations.map((s) => {
                return {
                    id: s.integrationConfigId,
                    network: s.integrationName,
                    label: s.mailchimpSegmentName ? s.mailchimpSegmentName : ''
                }
            }) : [];
        }

        get columns(): TableColumn[] {
            return [
                {key: 'network', label: this.$t('segment.network')},
                {key: 'label', label: this.$t('segment.network')},
                // {key: 'syncStatus', label: this.$t('integration.sync.status')},
                // {key: 'lastSyncOn', label: this.$t('integration.sync.lastSyncOn')},
            ];
        }

        public onRemove(integrationConfigId: string): void {
            this.removeSegmentIntegration({segmentId: this.$route.params.id, integrationConfigId});
        }

        public onAdd(integration: {integrationConfigId: string, integrationName: string, segmentConfig: null|{listId: string; segmentName: string}}): void {
        // public onAdd(integrationConfigId: string, integrationName: string, segmentConfig: object): void {
            const modal = this.$refs.add as ModalInterface;
            modal.hide();
            this.createSegmentIntegration({segmentId: this.$route.params.id, integrationConfigId: integration.integrationConfigId, integrationName: integration.integrationName, segmentConfig: integration.segmentConfig});
        }

        public openModal(): void {
            const modal = this.$refs.add as ModalInterface;
            modal.show();
        }

        public closeModal(): void {
            const modal = this.$refs.add as ModalInterface;
            modal.hide();
        }

        created() {
            this.fetchIntegrationConfigs();
            this.fetchSegmentIntegrations({segmentId: this.$route.params.id});
        }

    }
