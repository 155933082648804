

    import { Component, Prop, Vue } from 'vue-property-decorator'
    import {Tab} from '@/models/tab';


    @Component
    export default class Tabs extends Vue {
        @Prop({required: true}) private tabs!: Tab[];
        @Prop({default: false}) private isSub!: boolean;

    }
