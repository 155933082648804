
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import GroupCondition from '@/components/ConditionEditor/GroupCondition.vue';
    import {GroupCondition as GroupConditionModel} from '@/models/condition';
    import conditionValidator from '@/util/ConditionValidator';

    @Component({
        components: {
            GroupCondition,
        }
    })
    export default class ConditionEditor extends Vue {
        private conditions: GroupConditionModel|null = null;
        // @Prop({required: true}) private value!: GroupConditionModel;
        @Prop() private value!: GroupConditionModel;

        @Watch('value')
        valueChanged(newVal: GroupConditionModel) {
            this.setConditions(newVal);
        }

        @Emit("input")
        public emitInput(): GroupConditionModel|undefined {
            if(this.conditions) {
                return this.conditions;
            }
        }

        @Emit("validated")
        public emitValidated(): boolean {
            if(this.conditions) {
                return conditionValidator.validate(this.conditions);
            }
            return false;
        }

        public onChange(condition: GroupConditionModel): void {
            this.setConditions(condition);
            this.emitInput();
            this.emitValidated();
        }

        public setConditions(condition: GroupConditionModel): void {
            this.conditions = condition;
        }

        mounted() {
            this.setConditions(this.value);
        }
    }
