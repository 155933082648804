
    import {Component, Vue, Watch} from "vue-property-decorator";
    import {Action, Getter} from "vuex-class";
    import BaseLayout from "@/views/BaseLayout.vue";
    import Tabs from "@/components/Tabs.vue";
    import {Segment, SegmentType} from "@/models/segment";
    import {Tab} from '@/models/tab';
    import loading from '@/util/Loading';
    const namespace: string = 'segment';

    @Component({
        components: {
            BaseLayout,
            Tabs,
        }
    })
    export default class SegmentDetailView extends Vue {
        @Action('fetchSegment', { namespace }) fetchSegment: any;
        @Action('clearSegment', { namespace }) clearSegment: any;
        @Getter('segment', { namespace }) segment?: Segment;

        get isLoading(): boolean {
            // return false;
            return loading.isLoadingJobType(['segment-mutation', 'fetch-segment', 'fetch-option', 'fetch-integration-config']);
        }

        get isNew(): boolean {
            return this.$route.params.id === 'new';
        }

        get canEdit(): boolean {
            if((this.segment && this.segment.type === SegmentType.dynamic) || this.isNew) {
                return true;
            }
            return false
        }

        get tabs(): Tab[] {
            // if(this.canEdit) {
            //     return [
            //         {routeName: 'segment-detail-edit', label: this.$t('segment.edit')},
            //         {routeName: 'segment-detail-integration', label: this.$t('segment.integration')},
            //     ]
            // }
            return [
                {routeName: 'segment-detail-edit', label: this.$t('segment.edit')},
                {routeName: 'segment-detail-integration', label: this.$t('segment.integration')},
            ]
        }

        // TODO: display expected segment size
        // TODO: sync options of segments to: mailchimp, facebook ads, google ads

//         public onSegmentSave(segment: Segment): void {
// console.log(segment);
//         }

        created(): void {
            this.clearSegment();
        }

        mounted(): void {
            // if(!this.canEdit) {
            //     this.$router.push({name: 'segment-detail-integration', params: {id: this.$route.params.id}});
            // }

            if(!this.isNew) {
                this.fetchSegment({id: this.$route.params.id});
            }
        }

    }
