import {
    Condition, CurrencyAmountCondition,
    GroupCondition, instanceOfCurrencyAmount,
    instanceOfGroupCondition,
    instanceOfValueCondition,
    ValueCondition,
} from '@/models/condition';

class ConditionValidator {

    public validate(condition: Condition): boolean {
        if (instanceOfGroupCondition(condition)) {
            return this.validateGroupCondition(condition);
        } else if (instanceOfCurrencyAmount(condition)) {
            return this.validateCurrencyAmountCondition(condition);
        } else if (instanceOfValueCondition(condition)) {
            return this.validateValueCondition(condition);
        } else {
            return false;
        }
    }

    public validateGroupCondition(condition: GroupCondition): boolean {
        if (condition.operator !== null && condition.conditions.length !== 0 && this.validateGroupChildConditions(condition)) {
            return true;
        }
        return false;
    }

    private validateGroupChildConditions(condition: GroupCondition): boolean {
        for (let i = 0; i < condition.conditions.length; i++) {
            const isValid = this.validate(condition.conditions[i]);
            if(!isValid) {
                return false;
            }
        }
        return true;
    }

    public validateValueCondition(condition: ValueCondition): boolean {
        if (Array.isArray(condition.value) && condition.value.length === 0) {
            return false;
        }
        return !!(condition.fact !== null && condition.operator !== null && condition.value !== null && condition.value !== '');

    }

    public validateCurrencyAmountCondition(condition: CurrencyAmountCondition): boolean {
        return !!(condition.fact !== null
            && condition.operator !== null
            && condition.value !== null
            && condition.value.currencyCode !== null
            && condition.value.currencyCode !== ''
            && condition.value.amount !== null);

    }

}

export default new ConditionValidator();
