
    import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
    import {v4 as uuidv4} from "uuid";
    import ConditionEditor from "@/components/ConditionEditor/ConditionEditor.vue";
    import TitleInput from "@/components/Input/TitleInput.vue";
    import Button from "@/components/Button.vue";
    import {Segment, SegmentType} from "@/models/segment";
    import conditionValidator from '@/util/ConditionValidator';


    @Component({
        components: {
            ConditionEditor,
            TitleInput,
            'te-button': Button,
        }
    })
    export default class SegmentEditor extends Vue {
        private s: Segment = {
            id: null,
            name: '',
            type: SegmentType.dynamic,
            // integrations: null,
            conditions: {
                id: uuidv4(),
                operator: 'or',
                conditions: [{
                    id: uuidv4(),
                    fact: null,
                    operator: null,
                    value: null
                }]
            }
        };
        @Prop() private segment!: Segment;

        @Watch('segment')
        valueChanged(newSagment: Segment) {
            this.setSegment(newSagment);
        }

        @Emit("input")
        public onChange(): Segment {
            return this.s;
        }

        @Emit("save")
        public onSave(): Segment {
            return this.s;
        }

        @Emit("delete")
        public onDelete(): Segment {
            return this.s;
        }

        get isValid(): boolean {
            this.s.name = String(this.s.name).trim();
            if (this.s.name === null || this.s.name === '') return false;
            return conditionValidator.validate(this.s.conditions);
        }

        get canDelete(): boolean {
            if(this.s && this.s.id === null) {
                return false;
            }
            if(this.s && this.s.type === SegmentType.prebuild) {
                return false;
            }
            return true;
        }

        public setSegment(segment: Segment|null): void {
            if(segment) {
                this.s = segment;
            }
        }

        mounted() {
            this.setSegment(this.segment);
        }

    }
