
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import SelectBox from '@/components/Input/SelectBox.vue';
    import {ArrayConditionOption} from '@/models/condition';

    @Component({
        components: {
            SelectBox,
        }
    })
    export default class CurrencyAmountInput extends Vue {
        private currencyCode: string|null = null;
        private amount: number|null = null;
        @Prop() private value!: {currencyCode: string; amount: number;};
        @Prop() private currencyOptions!: ArrayConditionOption[];

        @Watch('value')
        valueChanged(newVal: {currencyCode: string; amount: number;}) {
            this.setCurrencyCode(newVal.currencyCode);
            this.setAmount(newVal.amount);
        }

        @Emit("input")
        public emitInput(): {currencyCode: string|null; amount: number|null;} {
            return {currencyCode: this.currencyCode, amount: this.amount};
        }

        get options(): ArrayConditionOption[] {
            if(this.currencyOptions) {
                return this.currencyOptions;
            }
            return [];
        }

        get currencyValue(): ArrayConditionOption | undefined {
            if(this.currencyOptions) {
                for (let i = 0; i < this.currencyOptions.length; i++) {
                    if(this.currencyOptions[i].id === this.currencyCode) {
                        return this.currencyOptions[i];
                    }
                }
            }
        }

        public setCurrencyCode(currencyCode: string): void {
            this.currencyCode = currencyCode;
        }

        public setAmount(amount: number): void {
            this.amount = amount;
        }

        public onCurrencyCodeChange(option: ArrayConditionOption): void {
            this.currencyCode = option.id;
            this.emitInput();
        }

        public onAmountChange(amount: number): void {
            this.amount = amount;
            this.emitInput();
        }

        mounted() {
            if(this.value && this.value.currencyCode) this.setCurrencyCode(this.value.currencyCode);
            if(this.value && this.value.amount) this.setAmount(this.value.amount);
        }

    }
