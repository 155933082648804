
    import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
    import {Action, Getter} from "vuex-class";
    import Button from "@/components/Button.vue";
    import SelectBox from '@/components/Input/SelectBox.vue';
    import {IntegrationConfig, Segment, SegmentIntegration} from "@/models/segment";
    import {MailchimpList} from '@/models/mailchimp';
    // import {Segment, SegmentType} from "@/models/segment";
    // import conditionValidator from '@/util/ConditionValidator';
    const namespace: string = 'segment';


    @Component({
        components: {
            // ConditionEditor,
            // TitleInput,
            SelectBox,
            'te-button': Button,
        }
    })
    export default class SegmentIntegrationEditor extends Vue {
        private integration: {integrationConfigId: string|null, integrationName: string|null, segmentConfig: null|{listId: string|undefined|null; segmentName: string|undefined|null}} = {
            integrationConfigId: null,
            integrationName: null,
            segmentConfig: null
        };
        private segmentName: string = 'Ticket Engine Segment';
        // @Prop() private configs!: IntegrationConfig[];
        // @Prop() private segmentIntegrations!: SegmentIntegration[];
        @Action('fetchMailchimpLists', { namespace }) fetchMailchimpLists: any;
        @Getter('segmentIntegrations', { namespace }) segmentIntegrations?: SegmentIntegration[];
        @Getter('integrationConfigs', { namespace }) integrationConfigs?: IntegrationConfig[];
        @Getter('mailchimpLists', { namespace }) mailchimpLists?: MailchimpList[];
        @Getter('segment', { namespace }) segment?: Segment;

        // @Watch('segment')
        // valueChanged(newSagment: Segment) {
        //     this.setSegment(newSagment);
        // }
        //
        // @Emit("input")
        // public onChange(): Segment {
        //     return this.s;
        // }

        @Emit("save")
        public onSave(): any {
            return this.integration;
        }

        @Emit("cancel")
        public onCancel(): any {
            return this.integration;
        }

        get configOptions(): Array<{id: string, label: string, type: string}> {
            if(this.integrationConfigs) {
                return this.integrationConfigs.map((c) => {
                    return {
                        id: c.id,
                        label: c.name,
                        type: c.integrationName
                    }
                })
            }
            return [];
        }

        get mailchimpListOptions(): Array<{id: string, label: string}> {
            if(this.mailchimpLists) {
                return this.mailchimpLists.map((c) => {
                    return {
                        id: c.id,
                        label: c.name,
                    }
                })
            }
            return [];
        }

        get isValid(): boolean {
            if(this.integration.integrationConfigId !== null
                && this.integration.integrationName === 'Mailchimp'
                && this.integration.segmentConfig
                && this.integration.segmentConfig.listId !== undefined
                && this.integration.segmentConfig.listId !== null
                && this.integration.segmentConfig.segmentName !== undefined
                && this.integration.segmentConfig.segmentName !== null
            ) {
                return true; // is valid mailchimp
            }
            return false;
        }

        get isMailchimp(): boolean {
            return !!(this.integration.integrationConfigId !== null
                && this.integration.integrationName === 'Mailchimp');
        }

        // get canDelete(): boolean {
        //     if(this.s && this.s.id === null) {
        //         return false;
        //     }
        //     if(this.s && this.s.type === SegmentType.prebuild) {
        //         return false;
        //     }
        //     return true;
        // }

        public onSelectConfig(config: {id: string, label: string, type: string}): void {
            this.integration.integrationConfigId = config.id;
            this.integration.integrationName = config.type;
            this.fetchMailchimpLists({integrationConfigId: config.id});
        }

        public onSelectMailchimpList(list: {id: string, name: string}): void {
            this.integration.segmentConfig = {
                listId: list.id,
                segmentName: this.segmentName
            };
        }

        @Watch('segment')
        nameChanged(segment: Segment) {
            this.segmentName = String(segment.name);
        }

        mounted() {
            if(this.segment && this.segment.name) {
                this.segmentName = String(this.segment.name);
            }
        }

    }
